import React from 'react'
// import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// import Admin from "./components/Admin/Admin"
// import AdminResult from "./components/Admin/AdminResult"
// import Form from "./components/PatientForm/PatientForm"
// import VaccineForm from "./components/VaccineForm/VaccineForm"
// import Landing from "./components/Landing/Landing"
// import LandingNavbar from "./components/LandingNavbar/LandingNavbar"
// import Help from "./components/Help/Help"
// import ContactUs from "./components/ContactUs/ContactUs"
// import Footer from "./components/Footer/Footer"
// import COVIDTesting from "./components/COVIDTesting/COVIDTesting"
// import COVIDVaccine from "./components/COVIDVaccine/COVIDVaccine"
// import Infusion from "./components/Infusion/Infusion"
// import HowTestingWorks from "./components/HowTestingWorks/HowTestingWorks"
// import Legal from "./components/Legal/Legal"

// import Test from "./Test"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from 'react-bootstrap/Image'

import { Helmet } from "react-helmet"
import Jumbotron from 'react-bootstrap/Jumbotron'

import { Navbar, Nav } from 'react-bootstrap'

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';

import "./App.css"

function App() {
  const shareUrl = "https://surfvitaminsea.com"

  return (
    <>
      <Helmet>
        <title>Home - Surf Vitamin Sea</title>
        <meta name="description" content="IV Vitamin therapy in the Orange County, California area." />
      </Helmet>
      <Col>
        <Row className='content-row top'>
          <Jumbotron className="landingJumbotron">
            <Row>
              <Col className="jumbotronCol" md="auto">
                <a href="/logo192.png"><Image className="logo" src={"/logo.png"} alt="Surf Vitamin Sea Logo" ></Image></a>
              </Col>
              <Col className="jumbotronCol" md="auto">
                <h1 className="jumbotronTitle">
                  <span>Vitamin</span>
                  <span className="jumbotronTitle" style={{color:"#00c4e3"}} >Sea</span>
                </h1>
                {/* <Row className="jumbotronHeaders">
                  <Col>
                    <h5 className="jumbotronHeader">Serving the Orange County Area</h5>
                  </Col>
                </Row> */}

                <ul>
                  <li className="jumbotronHeader">
                    <h4>Health and Wellness</h4>
                  </li>
                  <li className="jumbotronHeader">
                    <h4>Recovery</h4>
                  </li>
                  <li className="jumbotronHeader">
                    <h4>Beauty and Rejuvination</h4>
                  </li>
                </ul>
              </Col>
            </Row>

            <Row className='content-row'>
              <p>Surf Vitamin Sea specializes in providing IV vitamin therapy.
                IV therapy, also known as IV drip, is a common method used to deliver hydration and essential vitamins or minerals to the body through the bloodstream.
                Each IV drip is specifically crafted with different ingredients for the greatest effectiveness in targetting your use case, whether it be recovering from a sickness, or maximizing skin beauty.
                IV treatment is faster than digestion and oral supplementation, for immediate relief and benefits.</p>
            </Row>
          </Jumbotron>
        </Row>
      </Col>
      <Col>
        
        <Row className='product-row'>
          <Col md="auto">
            <h4 className='product-title'>Sickness Recovery</h4>
            <p>Invigorate your immune system, reduce pain, and support recovery with this powerful blend of vitamins, anti-oxidants, and medication.
              Get back better as soon as possible by fighting off your sickness more effectively with this treatment.
            </p>    
            <p><small>Order now for $299</small></p>   
          </Col>
          <Col md="auto">
            <h4 className='product-title'>Athletic Performance</h4>
            <p>Provide your body directly with vitamins, electrolytes, and hydration to boost energy and accelerate recovery.
              This treatment aims to improve athletic performance and promote growth and recovery after intense physical activity.
            </p>
            <p><small>Order now for $299</small></p>
          </Col>
        </Row>
        <Row className='product-row'>
          <Col md="auto">
            <h4 className='product-title'>Deep Tissue Massage</h4>
            <p>Therapeutic and reparative massage focusing on the deepest layers of muscle and tendon needing extra attention.
              Deep tissue massages provide relief for sore muscles, improve mobility, and help repair overused or strained parts.
            </p>
            <p><small>50 minutes for $149</small></p>
          </Col>
          <Col md="auto">
            <h4 className='product-title'>Vitality Massage</h4>
            <p>A combination of a traditional massage with additional remedies such as Vitamin B12, Vitamin C, and a blend of essential oils.
              These absorb directly on the skin and support restoration, health, and refresh both the mind and body.
            </p>  
            <p><small>80 minutes for $199</small></p>   
          </Col>
        </Row>
      </Col>
      <Navbar sticky="bottom" bg="dark" variant="dark" aria-label="Footer Contents">
            <Col aria-label="Footer Contents">

              <Col className="buttongroup">
                <Row className="center-col">
                  <Nav.Item aria-label="Share">Share us on Social Media</Nav.Item>
                </Row>
                <Row className="center-col">
                  <Nav.Item aria-label="Share on Facebook">
                    <FacebookShareButton url={shareUrl}>
                      <FacebookIcon size={30} aria-label="Facebook Share Button" />
                    </FacebookShareButton>
                  </Nav.Item>
                  <Nav.Item aria-label="Share on Twitter">
                    <TwitterShareButton url={shareUrl}>
                      <TwitterIcon size={30} aria-label="Twitter Share Button" />
                    </TwitterShareButton>
                  </Nav.Item>
                  <Nav.Item aria-label="Share on LinkedIn">
                    <LinkedinShareButton url={shareUrl}>
                      <LinkedinIcon size={30} aria-label="Linkedin Share Button" />
                    </LinkedinShareButton>
                  </Nav.Item>
                  <Nav.Item aria-label="Share with Email">
                    <EmailShareButton url={shareUrl}>
                      <EmailIcon size={30} aria-label="Email Share Button" />
                    </EmailShareButton>
                  </Nav.Item>
                </Row>
              </Col>
              {/* <Row className="center-col">
                            <Nav.Item className="footer-text" aria-label="Accessability Notice">
                                ETN Medical Infusion strives to be WCAG compliant, and if you have any issues in accessing the website, please call (714) 432-8884 during normal business hours for assistance.
                            </Nav.Item>
                        </Row> */}

              <Row className="center-col">
                <Nav.Item className="footer-text" aria-label="Address">
                  11100 Warner Ave. Suite 158
                  <br />
                  Fountain Valley, CA 92708
                </Nav.Item>
              </Row>

              <Row className="center-col">
                <Nav.Item className="footer-text" aria-label="Phone">
                  Phone: (714) 432-8884
                  <br />
                  Fax: (714) 988-2282
                </Nav.Item>
              </Row>

              <Row className="center-col">
                <Nav.Item className="footer-text" aria-label="Copyright Notice">
                  <small>
                    &copy; Copyright {new Date().getFullYear()}, Surf Vitamin Sea. All Rights Reserved.
                  </small>
                </Nav.Item>
              </Row>
            </Col>

          </Navbar>
    </>
  );
}

export default App;
